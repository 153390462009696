import auth from "../../middleware/auth";
import admin from "../../middleware/admin";
import guest from "../../middleware/guest";
import verified from "../../middleware/verified";

const routes = [
  {
    path: "/",
    component: () => import("../../components/view/Core.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../../views/Core/Home/Home.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: "courses",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "Courses",
            component: () => import("../../views/Core/Courses/Courses.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: ":idCourse",
            name: "CourseDetails",
            component: () => import("../../views/Core/Courses/Detail.vue"),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "my-course",
        name: "MyCourse",
        component: () => import("../../views/Core/MyCourse/MyCourse.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        component: () => import("../../views/Core/Calendar/Calendar.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: "tasks",
        name: "Tasks",
        component: () => import("../../views/Core/Tasks/Tasks.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: "get-started",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "GettingStarted",
            component: () =>
              import("../../views/Core/Getting Started/GetStarted.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "cefr",
            name: "GettingStartedCefrHome",
            component: () =>
              import("../../views/Core/Getting Started/GetStarted.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "sla",
            name: "GettingStartedSlaHome",
            component: () =>
              import("../../views/Core/Getting Started/GetStarted.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "mla",
            name: "GettingStartedMlaHome",
            component: () =>
              import("../../views/Core/Getting Started/GetStarted.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "vark",
            name: "GettingStartedVarkHome",
            component: () =>
              import("../../views/Core/Getting Started/GetStarted.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "vark/:idVark",
            name: "GettingStartedVark",
            component: () =>
              import("../../views/Core/Getting Started/Sub/Vark/Detail.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "vark/:idVark/start",
            name: "GettingStartedVarkStart",
            component: () =>
              import("../../views/Core/Getting Started/Sub/Vark/Start.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "vark/:idVark/recent",
            name: "GettingStartedVarkRecent",
            component: () =>
              import("../../views/Core/Getting Started/Sub/Vark/Recent.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "wa",
            name: "GettingStartedWaHome",
            component: () =>
              import("../../views/Core/Getting Started/GetStarted.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "wa/:idWa",
            name: "GettingStartedWa",
            component: () =>
              import("../../views/Core/Getting Started/Sub/Wa/Detail.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "wa/:idWa/start",
            name: "GettingStartedWaStart",
            component: () =>
              import("../../views/Core/Getting Started/Sub/Wa/Start.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "wa/:idWa/recent",
            name: "GettingStartedWaRecent",
            component: () =>
              import("../../views/Core/Getting Started/Sub/Wa/Recent.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "pt",
            name: "GettingStartedPtHome",
            component: () =>
              import("../../views/Core/Getting Started/GetStarted.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "pt/:idPT",
            name: "GettingStartedPT",
            component: () =>
              import("../../views/Core/Getting Started/Sub/PT/Detail.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "pt/:idPT/start",
            name: "GettingStartedPTStart",
            component: () =>
              import("../../views/Core/Getting Started/Sub/PT/Start.vue"),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "pt/:idPT/recent",
            name: "GettingStartedPTRecent",
            component: () =>
              import("../../views/Core/Getting Started/Sub/PT/Recent.vue"),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "meet-tutors",
        name: "MeetTutors",
        component: () => import("../../views/Core/MeetTutors/MeetTutors.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: "toefl",
        name: "TOEFL",
        component: () => import("../../views/Core/TOEFL/TOEFL.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: "toefl/history",
        name: "TOEFLHistory",
        component: () => import("../../views/Core/TOEFL/TOEFL.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: "class-registration",
        name: "ClassRegistration",
        component: () => import("../../views/Core/ClassRegistration/ClassRegistration.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: "class-registration/history",
        name: "ClassRegistrationHistory",
        component: () => import("../../views/Core/ClassRegistration/ClassRegistration.vue"),
        meta: {
          middleware: [verified],
        },
      },
    ],
  },
  {
    path: "/my-course/:idCourse",
    component: () => import("../../components/view/CourseCore.vue"),
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          next(`/my-course/${to.params.idCourse}/dashboard`);
          return;
        },
      },
      {
        path: "dashboard",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseDashboard",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Dashboard/Dashboard.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "announcements",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseAnnouncements",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Announcements/Announcements.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: ":idAnnouncement",
            name: "MyCourseAnnouncementsDetail",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Announcements/Detail.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "meet-tutors",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseMeetTutors",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/MeetTutors/MeetTutors.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "attendance",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseAttendance",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Attendance/Attendance.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "people",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCoursePeople",
            component: () =>
              import("../../views/Core/MyCourse/Detail/People/People.vue"),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "materials",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseMaterials",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Materials/Materials.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: ":idMaterial",
            name: "MyCourseMaterialsDetail",
            component: () =>
              import("../../views/Core/MyCourse/Detail/Materials/Detail.vue"),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "conferences",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseConferences",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Conferences/Conferences.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: ":idConference",
            name: "MyCourseConferencesDetail",
            component: () =>
              import("../../views/Core/MyCourse/Detail/Conferences/Detail.vue"),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "assessment-task",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseAssessmentTask",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/AssessmentTask/AssessmentTask.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "quiz",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idAssignment",
                name: "MyCourseAssessmentTaskQuiz",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Quiz/Quiz.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
              {
                path: ":idAssignment/start",
                name: "MyCourseAssessmentTaskQuizStart",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Quiz/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
          {
            path: "discussion",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idAssignment",
                name: "MyCourseAssessmentTaskDiscussion",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Discussion/Discussion.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
              {
                path: ":idAssignment/start",
                name: "MyCourseAssessmentTaskDiscussionStart",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Discussion/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
          {
            path: "achievement-test",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idAssignment",
                name: "MyCourseAssessmentTaskAchievementTest",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Achievement/Achievement.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
              {
                path: ":idAssignment/start",
                name: "MyCourseAssessmentTaskAchievementTestStart",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Achievement/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
          {
            path: "individual-assignment",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idAssignment",
                name: "MyCourseAssessmentTaskIndividualAssignment",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Individual/Individual.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
              {
                path: ":idAssignment/start",
                name: "MyCourseAssessmentTaskIndividualAssignmentStart",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Individual/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
          {
            path: "group-assignment",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idAssignment",
                name: "MyCourseAssessmentTaskGroupAssignment",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Group/Group.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
              {
                path: ":idAssignment/start",
                name: "MyCourseAssessmentTaskGroupAssignmentStart",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/AssessmentTask/Detail/Group/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
        ],
      },
      {
        path: "feedback",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseFeedback",
            component: () =>
              import("../../views/Core/MyCourse/Detail/Feedback/Feedback.vue"),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "progress-gradding",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseProgressGradding",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/ProgressGradding/ProgressGradding.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "goal-analytics",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseGoalAnalytics",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/GoalAnalytics/GoalAnalytics.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "evaluation",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseEvaluation",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Evaluation/Evaluation.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "skills",
            name: "MyCourseEvaluationSkills",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Evaluation/Evaluation.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "attitude",
            name: "MyCourseEvaluationAttitude",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Evaluation/Evaluation.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "reward",
            name: "MyCourseEvaluationReward",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Evaluation/Evaluation.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
        ],
      },
      {
        path: "reflection",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MyCourseReflection",
            component: () =>
              import(
                "../../views/Core/MyCourse/Detail/Reflection/Reflection.vue"
              ),
            meta: {
              middleware: [verified],
            },
          },
          {
            path: "open",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idQuestionnaire",
                name: "MyCourseReflectionQuestOpen",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/Reflection/Detail/QuestOpen/QuestOpen.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
              {
                path: ":idQuestionnaire/start",
                name: "MyCourseReflectionQuestOpenStart",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/Reflection/Detail/QuestOpen/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
          {
            path: "closed",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idQuestionnaire",
                name: "MyCourseReflectionQuestClosed",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/Reflection/Detail/QuestClose/QuestClose.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
              {
                path: ":idQuestionnaire/start",
                name: "MyCourseReflectionQuestClosedStart",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/Reflection/Detail/QuestClose/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
          {
            path: "rating",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idQuestionnaire",
                name: "MyCourseReflectionRating",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/Reflection/Detail/Rating/Rating.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
              {
                path: ":idQuestionnaire/start",
                name: "MyCourseReflectionRatingStart",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/Reflection/Detail/Rating/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
          {
            path: "interview",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idInterview",
                name: "MyCourseReflectionInterview",
                component: () =>
                  import(
                    "../../views/Core/MyCourse/Detail/Reflection/Detail/Interview/Start.vue"
                  ),
                meta: {
                  middleware: [verified],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/profile",
    component: () => import("../../components/view/Core.vue"),
    children: [
      {
        path: "",
        name: "Profile",
        component: () => import("../../views/Core/Profile/Profile.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: ":username",
        name: "ProfileUser",
        component: () => import("../../views/Core/ProfileUser/ProfileUser.vue"),
        meta: {
          middleware: [verified],
        },
      },
    ],
  },
  {
    path: "/change-password",
    component: () => import("../../components/view/Core.vue"),
    children: [
      {
        path: "",
        name: "ChangePassword",
        component: () => import("../../views/Core/ChangePassword.vue"),
        meta: {
          middleware: [verified],
        },
      },
    ],
  },
  {
    path: "/coupon",
    component: () => import("../../components/view/Core.vue"),
    children: [
      {
        path: "",
        name: "Coupon",
        component: () => import("../../views/Core/Coupon.vue"),
        meta: {
          middleware: [verified],
        },
      },
    ],
  },
  {
    path: "/notification",
    component: () => import("../../components/view/Core.vue"),
    children: [
      {
        path: "",
        name: "Notification",
        component: () => import("../../views/Core/Notification.vue"),
        meta: {
          middleware: [verified],
        },
      },
    ],
  },
  {
    path: "*",
    component: () => import("../../views/Error/404.vue"),
    meta: {
      layout: "BlankLayout",
    },
  },
  {
    path: "/invoice/:id",
    component: () => import("../../views/Core/Invoice.vue"),
    meta: {
      layout: "BlankLayout",
    },
  },
  {
    path: "/message",
    component: () => import("../../components/view/Blank.vue"),
    children: [
      {
        path: "",
        name: "Message",
        component: () => import("../../views/Messenger/Message.vue"),
        meta: {
          middleware: [verified],
        },
      },
      {
        path: ":idUser",
        name: "Chat",
        component: () => import("../../views/Messenger/Chat.vue"),
        meta: {
          middleware: [verified],
        },
      },
    ],
  },
  // {
  //   path: '/browse',
  //   component: () => import('../../components/view/Guest.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'Browse',
  //       component: () => import('../../views/Guest/Browse.vue'),
  //     },
  //     {
  //       path: 'courses',
  //       name: 'CoursesBrowse',
  //       component: () => import('../../views/Guest/Courses.vue'),
  //     },
  //     {
  //       path: 'courses/:idCourse',
  //       name: 'CoursesDetailBrowse',
  //       component: () => import('../../views/Guest/Courses/Detail.vue'),
  //     },
  //     {
  //       path: 'about',
  //       name: 'AboutBrowse',
  //       component:() => import('../../views/Guest/About.vue'),
  //     },
  //     {
  //       path: 'term-of-condition',
  //       name: 'TermConditionBrowse',
  //       component:() => import('../../views/Guest/TermCondition.vue'),
  //     },
  //     {
  //       path: 'blog',
  //       name: 'BlogBrowse',
  //       component:() => import('../../views/Guest/Blog/Blog.vue'),
  //     },
  //     {
  //       path: 'blog/:idArticle',
  //       name: 'BlogDetailBrowse',
  //       component:() => import('../../views/Guest/Blog/Detail.vue'),
  //     },
  //     {
  //       path: '/coming-soon',
  //       name: 'ComingSoonBrowse',
  //       component:() => import('../../components/helpers/ComingSoon.vue'),
  //     },
  //     {
  //       path: 'contact',
  //       name: 'ContactBrowse',
  //       component:() => import('../../views/Guest/Contact.vue'),
  //     },
  //   ],
  // },
  {
    path: "/",
    component: () => import("../../components/view/GuestNew.vue"),
    children: [
      {
        path: "/browse",
        name: "BrowseNew",
        component: () => import("../../views/GuestNew/Browse/Browse.vue"),
      },
      {
        path: "/lms",
        name: "LMSBrowse",
        component: () => import("../../views/GuestNew/AllServices/LMS.vue"),
      },
      {
        path: "/library",
        name: "LibraryBrowse",
        component: () => import("../../views/GuestNew/AllServices/Library.vue"),
      },
      {
        path: "/cloud",
        name: "CloudBrowse",
        component: () => import("../../views/GuestNew/AllServices/Cloud.vue"),
      },
      {
        path: "/community",
        name: "CommunityBrowse",
        component: () =>
          import("../../views/GuestNew/AllServices/Community.vue"),
      },
      {
        path: "/thread",
        name: "ThreadBrowse",
        component: () => import("../../views/GuestNew/AllServices/Thread.vue"),
      },
      {
        path: "/about",
        name: "AboutBrowseNew",
        component: () => import("../../views/GuestNew/About/About.vue"),
      },
      {
        path: "/blog",
        name: "BlogBrowseNew",
        component: () => import("../../views/GuestNew/Blog/Blog.vue"),
      },
      {
        path: "/blog/list",
        name: "BlogListBrowseNew",
        component: () => import("../../views/GuestNew/Blog/BlogList.vue"),
      },
      {
        path: "/blog/detail/:idBlog",
        name: "BlogDetailBrowseNew",
        component: () => import("../../views/GuestNew/Blog/Detail/Detail.vue"),
      },
      {
        path: "/event",
        name: "EventsBrowseNew",
        component: () => import("../../views/GuestNew/Events/Events.vue"),
      },
      {
        path: "/event/detail/:idEvent",
        name: "EventDetailBrowseNew",
        component: () => import("../../views/GuestNew/Events/Detail.vue"),
      },
      {
        path: "/toefl-itp",
        name: "TOEFLITPBrowse",
        component: () =>
          import("../../views/GuestNew/TestPrograms/TOEFL-ITP.vue"),
      },
      {
        path: "/toefl-ibt",
        name: "TOEFLIBTBrowse",
        component: () =>
          import("../../views/GuestNew/TestPrograms/TOEFL-IBT.vue"),
      },
      {
        path: "/toeic",
        name: "TOEICBrowse",
        component: () => import("../../views/GuestNew/TestPrograms/TOEIC.vue"),
      },
      {
        path: "/ielts",
        name: "IELTSBrowse",
        component: () => import("../../views/GuestNew/TestPrograms/IELTS.vue"),
      },
      {
        path: "/term-of-condition",
        name: "TermConditionBrowse",
        component: () => import("../../views/Guest/TermCondition.vue"),
      },
    ],
  },

  {
    path: "/403",
    name: "403",
    component: () => import("../../views/Error/403.vue"),
    meta: {
      layout: "BlankLayout",
    },
  },
  {
    path: "/login",
    component: () => import("../../views/Auth/Login.vue"),
    name: "Login",
    meta: {
      middleware: [guest],
      layout: "BlankLayout",
    },
  },
  {
    path: "/register",
    component: () => import("../../views/Auth/Register.vue"),
    name: "Register",
    meta: {
      middleware: [guest],
      layout: "BlankLayout",
    },
  },
  {
    path: "/verification",
    component: () => import("../../views/Auth/Verification.vue"),
    name: "Verification",
    meta: {
      middleware: [auth],
      layout: "BlankLayout",
    },
  },
  {
    path: "/payment-callback",
    component: () => import("../../views/Core/TransactionCallback.vue"),
    name: "PaymentCallback",
    meta: {
      middleware: [auth],
      layout: "BlankLayout",
    },
  },
  {
    path: "/payment-success",
    component: () => import("../../views/Core/PaymentSuccess.vue"),
    name: "PaymentSuccess",
    meta: {
      layout: "BlankLayout",
    },
  },
  {
    path: "/payment-failed",
    component: () => import("../../views/Core/PaymentFailed.vue"),
    name: "PaymentFailed",
    meta: {
      layout: "BlankLayout",
    },
  },
  {
    path: "/forgot-password",
    component: () => import("../../views/Auth/ForgotPassword.vue"),
    name: "ForgotPassword",
    meta: {
      middleware: [guest],
      layout: "BlankLayout",
    },
  },
  {
    path: "/reset-password",
    component: () => import("../../views/Auth/ResetPassword.vue"),
    name: "ResetPassword",
    meta: {
      middleware: [guest],
      layout: "BlankLayout",
    },
  },
  {
    path: "/admin",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "HomeAdmin",
        component: () => import("../../views/Admin/Home.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/dashboard",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "DashboardAdmin",
        component: () => import("../../views/Admin/Dashboard/Dashboard.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/courses",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "CoursesAdmin",
        component: () => import("../../views/Admin/Courses/Courses.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "create",
        component: () => import("../../views/Admin/Courses/CreateCourse.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/courses/:idCourse",
    component: () => import("../../components/view/CourseAdmin.vue"),
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          next(`/admin/courses/${to.params.idCourse}/home`);
          return;
        },
      },
      {
        path: "home",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "GetCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Home.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "edit",
            name: "EditCoursesAdmin",
            component: () => import("../../views/Admin/Courses/EditCourse.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "duplicate",
            name: "DuplicateCoursesAdmin",
            component: () => import("../../views/Admin/Courses/DuplicateCourse.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "meet-tutors",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "MeetTutorsCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/MeetTutors/MeetTutors.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "announcements",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "AnnouncementCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Announcements/Announcement.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "create",
            name: "AnnouncementCoursesCreateAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Announcements/Create.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: ":idAnnouncement/edit",
            name: "AnnouncementCoursesEditAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Announcements/Edit.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: ":idAnnouncement",
            name: "AnnouncementDetailsAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Announcements/Detail.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "assignments",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "AssignmentsCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Assignments/Assignments.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "discussion",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "AssignmentsDiscussionCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Assignments/Sub/Discussion/Create"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idAssignment",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "edit",
                    name: "AssignmentsDiscussionEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Discussion/Edit"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "task",
                    name: "AssignmentsDiscussionTaskCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Discussion/Task"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-list",
                    name: "AssignmentsDiscussionSubmissionListCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Discussion/Submission/SubmissionList"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-detail/:submissionId",
                    name: "AssignmentsDiscussionSubmissionDetalCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Discussion/Submission/SubmissionDetail"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "quiz",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "AssignmentsQuizCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Assignments/Sub/Quiz/Create"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idAssignment",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "edit",
                    name: "AssignmentsQuizEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Quiz/Edit"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "task",
                    name: "AssignmentsQuizTaskCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Quiz/Task"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-list",
                    name: "AssignmentsQuizSubmissionListCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Quiz/Submission/SubmissionList"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-detail/:submissionId",
                    name: "AssignmentsQuizSubmissionDetalCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Quiz/Submission/SubmissionDetail"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "individual",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "AssignmentsIndividualCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Assignments/Sub/Individual/Create"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idAssignment",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "edit",
                    name: "AssignmentsIndividualEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Individual/Edit"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "task",
                    name: "AssignmentsIndividualTaskCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Individual/Task"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-list",
                    name: "AssignmentsIndividualSubmissionListCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Individual/Submission/SubmissionList"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-detail/:submissionId",
                    name: "AssignmentsIndividualSubmissionDetalCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Individual/Submission/SubmissionDetail"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "group",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "AssignmentsGroupCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Assignments/Sub/Group/Create"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idAssignment",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "edit",
                    name: "AssignmentsGroupEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Group/Edit"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "task",
                    name: "AssignmentsGroupTaskCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Group/Task"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-list",
                    name: "AssignmentsGroupSubmissionListCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Group/Submission/SubmissionList"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-detail/:submissionId",
                    name: "AssignmentsGroupSubmissionDetalCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Group/Submission/SubmissionDetail"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "achievement",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "AssignmentsAchievementCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Assignments/Sub/Achievement/Create"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idAssignment",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "edit",
                    name: "AssignmentsAchievementEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Achievement/Edit"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "task",
                    name: "AssignmentsAchievementTaskCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Achievement/Task"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-list",
                    name: "AssignmentsAchievementSubmissionListCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Achievement/Submission/SubmissionList"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-detail/:submissionId",
                    name: "AssignmentsAchievementSubmissionDetalCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Assignments/Sub/Achievement/Submission/SubmissionDetail"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "grades",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "GradesCoursesAdmin",
            component: () => import("../../views/Error/ComingSoon.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "people",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "PeopleCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/People/People.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "group/edit/:idGroup",
            name: "PeopleGroupEditCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/People/Group/Edit.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "group/create",
            name: "PeopleGroupCreateCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/People/Group/Create.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "group/member/:idGroup",
            name: "PeopleGroupMemberCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/People/Group/Member/Member.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "files",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "FilesCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Files/Files.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "recorded",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "detail/:idFile",
                name: "FilesDetailRecordedCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Recorded/Detail.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "create",
                name: "FilesCreateRecordedCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Recorded/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "edit/:idFile",
                name: "FilesEditRecordedCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Recorded/Edit.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
          {
            path: "animated",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "detail/:idFile",
                name: "FilesDetailAnimatedCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Animated/Detail.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "create",
                name: "FilesCreateAnimatedCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Animated/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "edit/:idFile",
                name: "FilesEditAnimatedCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Animated/Edit.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
          {
            path: "podcast",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "detail/:idFile",
                name: "FilesDetailPodcastCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Podcast/Detail.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "create",
                name: "FilesCreatePodcastCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Podcast/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "edit/:idFile",
                name: "FilesEditPodcastCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Podcast/Edit.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
          {
            path: "document",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "detail/:idFile",
                name: "FilesDetailDocumentCoursesAdmin",
                component: () =>
                  import("../../views/Admin/Courses/Detail/Files/Detail.vue"),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "create",
                name: "FilesCreateDocumentCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Document/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "edit/:idFile",
                name: "FilesEditDocumentCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Files/Document/Edit.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
        ],
      },
      {
        path: "syllabus",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "SyllabusCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Syllabus/Syllabus.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "create",
            name: "SyllabusCreateCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Syllabus/Create.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "edit/:idSyllabus",
            name: "SyllabusEditCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Syllabus/Edit.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "point/:idSyllabus",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "detail/:idPoint",
                name: "SyllabusPointCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Syllabus/Detail/Point.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "create",
                name: "SyllabusPointCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Syllabus/Detail/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "edit/:idPoint",
                name: "SyllabusPointEditCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Syllabus/Detail/Edit.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: "detail/:idPoint/target",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "create",
                    name: "SyllabusPointTargetCreateCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Syllabus/Detail/Target/Create.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "edit/:idTarget",
                    name: "SyllabusPointTargetEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Syllabus/Detail/Target/Edit.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "outcomes",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "OutcomesCoursesAdmin",
            component: () => import("../../views/Error/ComingSoon.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "rubrics",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "RubricsCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Rubrics/Rubrics.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "create",
            name: "RubricsCoursesCreateAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Rubrics/Create.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "edit/:idRubric",
            name: "RubricsCoursesEditAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Rubrics/Edit.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: ":idRubric/criteria",
            name: "RubricsCoursesCriteriaAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Rubrics/Detail/Criteria.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: ":idRubric/criteria/create",
            name: "RubricsCoursesCriteriaCreateAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Rubrics/Detail/Create.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: ":idRubric/criteria/edit/:idCriteria",
            name: "RubricsCoursesCriteriaEditAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Rubrics/Detail/Edit.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "quizzes",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "QuizzesCoursesAdmin",
            component: () => import("../../views/Error/ComingSoon.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "modules",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "ModulesCoursesAdmin",
            component: () => import("../../views/Error/ComingSoon.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "conferences",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "ConferencesCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Conference/Conference.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "create",
            name: "ConferencesCoursesCreateAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Conference/Create.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: ":idConference",
            name: "ConferencesCoursesDetailsAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Conference/Detail.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "collaborations",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "CollaborationsCoursesAdmin",
            component: () => import("../../views/Error/ComingSoon.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "attendance",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "AttendanceCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Attendance/Attendance.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "coupon",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "CouponCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Coupon/Coupon.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "create",
            name: "CreateCouponCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Coupon/CreateCoupon.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: ":idCoupon/edit",
            name: "EditCouponCoursesAdmin",
            component: () =>
              import("../../views/Admin/Courses/Detail/Coupon/EditCoupon.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "progress-grading",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "ProgressGradingCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/ProgressGrading/ProgressGrading.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "detail/:idStudent",
            name: "ProgressGradingDetailCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/ProgressGrading/ProgressGradingDetail.vue"
              ),
            meta: {
              middleware: [admin],
            },
          }
        ],
      },
      {
        path: "goal-analytics",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "GoalAnalyticsCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/GoalAnalytics/GoalAnalytics.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "detail/:idStudent",
            name: "GoalAnalyticsDetailCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/GoalAnalytics/GoalAnalyticsDetail.vue"
              ),
            meta: {
              middleware: [admin],
            },
          }
        ],
      },
      {
        path: "evaluation",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "EvaluationCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Evaluation/Evaluation.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "knowledge",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idStudent/info",
                name: "KnowledgeInfoEvaluationCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Evaluation/Sub/Info/Knowledge.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
          {
            path: "skills",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idStudent/info",
                name: "SkillsInfoEvaluationCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Evaluation/Sub/Info/Skills.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
          {
            path: "attitude",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idStudent/info",
                name: "AttitudeInfoEvaluationCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Evaluation/Sub/Info/Attitude.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
          {
            path: "reward-recognition",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: ":idStudent/info",
                name: "RewardRecognitionInfoEvaluationCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Evaluation/Sub/Info/RewardRecognition.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
        ],
      },
      {
        path: "reflection",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "",
            name: "ReflectionCoursesAdmin",
            component: () =>
              import(
                "../../views/Admin/Courses/Detail/Reflection/Reflection.vue"
              ),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "open",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "ReflectionOpenCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Reflection/Sub/Open/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idQuestionnaire",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "edit",
                    name: "ReflectionOpenEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Open/Edit.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "task",
                    name: "ReflectionOpenTaskCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Open/Task.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission",
                    name: "ReflectionOpenSubmissionCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Open/Submission/Submission.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-list",
                    name: "OpenQuestionnaireSubmissionList",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Open/Submission/SubmissionList.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-detail/:submissionId",
                    name: "OpenQuestionnaireSubmissionDetail",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Open/Submission/SubmissionDetail.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "closed",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "ReflectionClosedCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Reflection/Sub/Closed/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idQuestionnaire",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "edit",
                    name: "ReflectionClosedEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Closed/Edit.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "task",
                    name: "ReflectionClosedTaskCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Closed/Task.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission",
                    name: "ReflectionClosedSubmissionCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Closed/Submission/Submission.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-list",
                    name: "ClosedQuestionnaireSubmissionList",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Closed/Submission/SubmissionList.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-detail/:submissionId",
                    name: "ClosedQuestionnaireSubmissionDetail",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Closed/Submission/SubmissionDetail.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "rating-scale",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "ReflectionRatingCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Reflection/Sub/Rating/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idQuestionnaire",
                component: () => import("../../components/view/Blank.vue"),
                children: [
                  {
                    path: "edit",
                    name: "ReflectionRatingEditCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Rating/Edit.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "task",
                    name: "ReflectionRatingTaskCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Rating/Task.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission",
                    name: "ReflectionRatingSubmissionCoursesAdmin",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Rating/Submission/Submission.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-list",
                    name: "RatingQuestionnaireSubmissionList",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Rating/Submission/SubmissionList.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                  {
                    path: "submission-detail/:submissionId",
                    name: "RatingQuestionnaireSubmissionDetail",
                    component: () =>
                      import(
                        "../../views/Admin/Courses/Detail/Reflection/Sub/Rating/Submission/SubmissionDetail.vue"
                      ),
                    meta: {
                      middleware: [admin],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "interview",
            component: () => import("../../components/view/Blank.vue"),
            children: [
              {
                path: "create",
                name: "ReflectionInterviewCreateCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Reflection/Sub/Interview/Create.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
              {
                path: ":idInterview/detail",
                name: "ReflectionInterviewDetailCoursesAdmin",
                component: () =>
                  import(
                    "../../views/Admin/Courses/Detail/Reflection/Sub/Interview/Detail.vue"
                  ),
                meta: {
                  middleware: [admin],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/admin/calendar",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "CalendarAdmin",
        component: () => import("../../views/Admin/Calendar/Calendar.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/inbox",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "InboxAdmin",
        component: () => import("../../views/Admin/Inbox/Inbox.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/history",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "HistoryAdmin",
        component: () => import("../../views/Admin/History/History.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/blog",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "BlogAdmin",
        component: () => import("../../views/Admin/Blog/Blog.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "create",
        name: "BlogCreateAdmin",
        component: () => import("../../views/Admin/Blog/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "edit/:idArticle",
        name: "BlogEditAdmin",
        component: () => import("../../views/Admin/Blog/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "scr/:idArticle",
        name: "BlogScrAdmin",
        component: () => import("../../views/Admin/Blog/Scr.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/users",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "UsersAdmin",
        component: () => import("../../views/Admin/Users/Users.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "students",
        name: "UsersStudentAdmin",
        component: () => import("../../views/Admin/Users/Students.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "create",
        name: "UsersAdminCreate",
        component: () => import("../../views/Admin/Users/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: ":idUser/edit",
        name: "UsersAdminEdit",
        component: () => import("../../views/Admin/Users/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: ":idUser/edit_password",
        name: "UsersAdminEditPassword",
        component: () => import("../../views/Admin/Users/EditPass.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/privillage",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "PrivillageAdmin",
        component: () => import("../../views/Admin/Privillage/Privillage.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "permission/:idRole",
        name: "PrivillagePermissionAdmin",
        component: () => import("../../views/Admin/Privillage/Permission.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "create",
        name: "PrivillageCreateAdmin",
        component: () => import("../../views/Admin/Privillage/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "edit/:idRole",
        name: "PrivillageEditAdmin",
        component: () => import("../../views/Admin/Privillage/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/settings",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "SettingsAdmin",
        component: () => import("../../views/Admin/Settings/Settings.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "master",
        name: "SettingsMasterAdmin",
        component: () => import("../../views/Admin/Settings/Settings.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "test-program-date",
        name: "SettingsTestProgramDateAdmin",
        component: () => import("../../views/Admin/Settings/Settings.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "about",
        name: "SettingsAboutAdmin",
        component: () => import("../../views/Admin/Settings/Settings.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "tnc",
        name: "SettingsTncAdmin",
        component: () => import("../../views/Admin/Settings/Settings.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "testimony",
        name: "SettingsTestimonyAdmin",
        component: () => import("../../views/Admin/Settings/Settings.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "payment-methods",
        name: "SettingsPaymentMethodAdmin",
        component: () => import("../../views/Admin/Settings/Settings.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "partner",
        name: "SettingsPartnerAdmin",
        component: () => import("../../views/Admin/Settings/Settings.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "home-slider",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "create",
            name: "SettingsAdminCreateHomeSlider",
            component: () =>
              import("../../views/Admin/Settings/Sub/Slider/Create.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "edit/:idSlider",
            name: "SettingsAdminEditHomeSlider",
            component: () =>
              import("../../views/Admin/Settings/Sub/Slider/Edit.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "vark",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "create",
            name: "SettingsAdminCreateVark",
            component: () =>
              import("../../views/Admin/Settings/Sub/Vark/Create.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "edit/:idVark",
            name: "SettingsAdminEditVark",
            component: () =>
              import("../../views/Admin/Settings/Sub/Vark/Edit.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "detail/:idVark",
            name: "SettingsAdminDetailVark",
            component: () =>
              import("../../views/Admin/Settings/Sub/Vark/Detail.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "submission/:idVark",
            name: "SettingsAdminSubmissionVark",
            component: () =>
              import("../../views/Admin/Settings/Sub/Vark/Submission.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "wa",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "create",
            name: "SettingsAdminCreateWa",
            component: () =>
              import("../../views/Admin/Settings/Sub/Wa/Create.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "edit/:idWa",
            name: "SettingsAdminEditWa",
            component: () =>
              import("../../views/Admin/Settings/Sub/Wa/Edit.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "detail/:idWa",
            name: "SettingsAdminDetailWa",
            component: () =>
              import("../../views/Admin/Settings/Sub/Wa/Detail.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "submission/:idWa",
            name: "SettingsAdminSubmissionWa",
            component: () =>
              import("../../views/Admin/Settings/Sub/Wa/Submission.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
      {
        path: "pt",
        component: () => import("../../components/view/Blank.vue"),
        children: [
          {
            path: "create",
            name: "SettingsAdminCreatePT",
            component: () =>
              import("../../views/Admin/Settings/Sub/PT/Create.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "edit/:idPT",
            name: "SettingsAdminEditPT",
            component: () =>
              import("../../views/Admin/Settings/Sub/PT/Edit.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "detail/:idPT",
            name: "SettingsAdminDetailPT",
            component: () =>
              import("../../views/Admin/Settings/Sub/PT/Detail.vue"),
            meta: {
              middleware: [admin],
            },
          },
          {
            path: "submission/:idPT",
            name: "SettingsAdminSubmissionPT",
            component: () =>
              import("../../views/Admin/Settings/Sub/PT/Submission.vue"),
            meta: {
              middleware: [admin],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/admin/settings/about",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "content/create",
        name: "SettingsAboutContentCreateAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/About/Content/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "content/edit/:idContent",
        name: "SettingsAboutContentEditAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/About/Content/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "program/create",
        name: "SettingsAboutProgramCreateAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/About/Program/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "program/edit/:idProgram",
        name: "SettingsAboutProgramEditAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/About/Program/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "faq/create",
        name: "SettingsFAQCreateAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/About/FAQ/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "faq/edit/:idFAQ",
        name: "SettingsFAQEditAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/About/FAQ/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "milestones/create",
        name: "SettingsMilestoneCreateAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/About/Milestone/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "milestones/edit/:idMilestone",
        name: "SettingsMilestoneEditAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/About/Milestone/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/settings",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "test-program-date/create",
        name: "SettingsTestProgramDateCreateAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/TestProgramDate/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "test-program-date/edit/:idRegisterDate",
        name: "SettingsTestProgramDateEditAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/TestProgramDate/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "testimony/create",
        name: "SettingsTestimonyCreateAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/Testimony/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "testimony/edit/:idTestimony",
        name: "SettingsTestimonyEditAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/Testimony/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "payment-methods/create",
        name: "SettingsPaymentMethodCreateAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/PaymentMethod/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "payment-methods/edit/:idMethod",
        name: "SettingsPaymentMethodEditAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/PaymentMethod/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "partner/create",
        name: "SettingsPartnerCreateAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/Partner/Create.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "partner/edit/:idPartner",
        name: "SettingsPartnerEditAdmin",
        component: () =>
          import("../../views/Admin/Settings/Sub/Partner/Edit.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/help",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "HelpAdmin",
        component: () => import("../../views/Admin/Help/Help.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "file-repository/create",
        name: "HelpFileRepositoryCreateAdmin",
        component: () => import("../../views/Admin/Help/Sub/File/Create"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/toefl",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "TOEFLAdmin",
        component: () => import("../../views/Admin/TOEFL/TOEFL.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "setting",
        name: "TOEFLSettingAdmin",
        component: () => import("../../views/Admin/TOEFL/TOEFL.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/class-registration",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "ClassRegistrationAdmin",
        component: () => import("../../views/Admin/ClassRegistration/ClassRegistration.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "program-setting",
        name: "ClassRegistrationProgramSettingAdmin",
        component: () => import("../../views/Admin/ClassRegistration/ProgramSetting.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "batch-setting",
        name: "ClassRegistrationBatchSettingAdmin",
        component: () => import("../../views/Admin/ClassRegistration/BatchSetting.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
  {
    path: "/admin/event",
    component: () => import("../../components/view/Admin.vue"),
    children: [
      {
        path: "",
        name: "EventAdmin",
        component: () => import("../../views/Admin/Event/Event.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "create",
        name: "EventCreateAdmin",
        component: () => import("../../views/Admin/Event/EventCreate.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "edit/:idArticle",
        name: "EventEditAdmin",
        component: () => import("../../views/Admin/Event/EventEdit.vue"),
        meta: {
          middleware: [admin],
        },
      },

      {
        path: "category",
        name: "EventCategoryAdmin",
        component: () => import("../../views/Admin/Event/Category.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "category/create",
        name: "EventCategoryCreateAdmin",
        component: () => import("../../views/Admin/Event/CategoryCreate.vue"),
        meta: {
          middleware: [admin],
        },
      },

      {
        path: "testimony",
        name: "EventTestimonyAdmin",
        component: () => import("../../views/Admin/Event/Testimony.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "testimony/create",
        name: "EventTestimonyCreateAdmin",
        component: () => import("../../views/Admin/Event/TestimonyCreate.vue"),
        meta: {
          middleware: [admin],
        },
      },
      {
        path: "testimony/edit/:idArticle",
        name: "EventTestimonyEditAdmin",
        component: () => import("../../views/Admin/Event/TestimonyEdit.vue"),
        meta: {
          middleware: [admin],
        },
      },
    ],
  },
];

export default routes;
